// @ts-nocheck
import { Checkout } from '@ggs/components/account/AccountPanelItems'
import { GridContainer, Layout } from '@ggs/components/layout'
import { ProductGrid } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib' // Assets
import { colors } from '@ggs/styles'
import { graphql } from 'gatsby'
import React from 'react'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const CheckoutLayout = (props) => {
  const pageContext = props?.pageContext || {}
  // const { orderComplete } = useContext(CheckoutContext)
  const { t } = useI18n()
  const title = t('ecomm:label.checkout')
  const {
    data: {
      layoutMeta: {
        currentStore: { ecommEnabled = false },
      },
    },
  } = pageContext

  // console.log(pageContext)
  const data = pageContext?.data
  const { checkoutFeaturedProducts } = data || {}
  pageContext.data.title = title

  console.log('CheckoutLayout', {
    // orderComplete: orderComplete,
    ecommEnabled: ecommEnabled,
    data,
  })

  // useEffect(() => {
  //   // if (currentStepName === 'COMPLETE') {
  //   //   goToOrder()
  //   // }
  //   console.log(lastOrder)
  // }, [
  //   lastOrder,
  //   // currentStepName
  // ])

  return (
    <Layout
      className="checkout"
      site={props?.queries?.site}
      pageContext={pageContext}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
      checkout={true}
    >
      <GridContainer
        className={'checkout-layout'}
        sx={{
          pb: {
            xs: 2,
            md: 10,
          },
          pt: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Checkout ecommEnabled={ecommEnabled} {...data} />
      </GridContainer>
      <ProductGrid
        className="checkout__related"
        relatedProducts={checkoutFeaturedProducts}
        actionLabel={t('ecomm:product.button.buyNow')}
        container={{
          fullWidthBackground: true,
          backgroundColor: colors.ultraLightBlue,
        }}
        type={'Checkout-ProductGrid'}
        id={'Checkout-ProductGrid'}
      />
    </Layout>
  )
}

export const query = graphql`
  query checkoutPage($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global", "ecomm", "account"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CheckoutLayout
